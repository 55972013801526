import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const prefix = '/'

const routes = [
	
	{
		path: prefix
		,name: 'intro'
		,component: () => import('@/view/Main/Index.vue')
	}
	,{
		path: prefix + 'Auth/Notice'
		,name: 'AuthNotice'
		,component: () => import('@/view/Auth/Notice.vue')
	}
	,{
		path: prefix + 'Index'
		,name: 'Index'
		,component: () => import('@/view/Main/Index.vue')
	}
	,{
		path: prefix + 'Dashboard'
		,name: 'Dashboard'
		,component: () => import('@/view/Main/Dashboard.vue')
	}
	,{
		path: prefix + 'DashboardMulti'
		,name: 'DashboardMulti'
		,component: () => import('@/view/Main/DashboardMulti.vue')
	}
	,{
		path: prefix + 'Auth/Login'
		,name: 'Login'
		,component: () => import('@/view/Auth/Login.vue')
	}
	,{
		path: prefix + 'Auth/Find/Id'
		,name: 'FindId'
		,component: () => import('@/view/Auth/FindId.vue')
	}
	,{
		path: prefix + 'Auth/Find/Pw'
		,name: 'FindPw'
		,component: () => import('@/view/Auth/FindPw.vue')
	}
	,{
		path: prefix + 'Auth/Join/:code?'
		,name: 'Join'
		,component: () => import('@/view/Auth/Join.vue')
	}
	,{
		path: prefix + 'Auth/Result'
		,name: 'JoinResult'
		,component: () => import('@/view/Auth/Result.vue')
	}
	,{
		path: prefix + 'Member/List/:page?'
		,name: 'MemberList'
		,component: () => import('@/view/Member/MemberList.vue')
	}
	,{
		path: prefix + 'Member/Point'
		,name: 'MemberPoint'
		,component: () => import('@/view/Member/MemberPoint.vue')
	}
	,{
		path: prefix + 'Member/Item'
		,name: 'MemberItem'
		,component: () => import('@/view/Member/MemberItem.vue')
	}
	,{
		path: prefix + 'Member/Detail/:idx'
		,name: 'MemberDetail'
		,component: () => import('@/view/Member/MemberDetail.vue')
	}
	,{
		path: prefix + 'Member/OperatorList/:page?'
		,name: 'MemberOperatorList'
		,component: () => import('@/view/Member/OperatorList.vue')
	}
	,{
		path: prefix + 'Member/OperatorItem'
		,name: 'MemberOperatorItem'
		,component: () => import('@/view/Member/OperatorItem.vue')
	}
	,{
		path: prefix + 'Member/OperatorDetail/:idx'
		,name: 'MemberOperatorDetail'
		,component: () => import('@/view/Member/OperatorDetail.vue')
	}
	,{
		path: prefix + 'Manager/List/:page?'
		,name: 'ManagerList'
		,component: () => import('@/view/Manager/ManagerList.vue')
	}
	,{
		path: prefix + 'Manager/Item'
		,name: 'ManagerItem'
		,component: () => import('@/view/Manager/ManagerItem.vue')
	}
	,{
		path: prefix + 'Manager/Detail/:idx'
		,name: 'ManagerDetail'
		,component: () => import('@/view/Manager/ManagerDetail.vue')
	}
	,{
		path: prefix + 'Manager/Setting'
		,name: 'ManagerSetting'
		,component: () => import('@/view/Manager/Setting.vue')
	}
	,{
		path: prefix + 'Pg/List'
		,name: 'PgList'
		,component: () => import('@/view/Pg/PgList.vue')
	}
	,{
		path: prefix + 'Pg/NotiList'
		,name: 'PgNotiList'
		,component: () => import('@/view/Pg/PgNotiList.vue')
	}
	,{
		path: prefix + 'Pg/Item'
		,name: 'PgItem'
		,component: () => import('@/view/Pg/PgItem.vue')
	}
	,{
		path: prefix + 'Pg/Detail/:id'
		,name: 'PgDetail'
		,component: () => import('@/view/Pg/PgDetail.vue')
	}
	,{
		path: prefix + 'Statistics/List/:date?'
		,name: 'StatisticsList'
		,component: () => import('@/view/Statistics/StatisticsList')
	}
	,{
		path: prefix + 'Statistics/Mobile'
		,name: 'StatisticsMobile'
		,component: () => import('@/view/Statistics/StatisticsMobile.vue')
	}
	,{
		path: prefix + 'Statistics/Multi/:date?'
		,name: 'StatisticsMulti'
		,component: () => import('@/view/Statistics/StatisticsMulti')
	}
	,{
		path: prefix + 'Admin/List'
		,name: 'AdminList'
		,component: () => import('@/view/Admin/AdminList.vue')
	}
	,{
		path: prefix + 'Admin/Item'
		,name: 'AdminItem'
		,component: () => import('@/view/Admin/AdminItem.vue')
	}
	,{
		path: prefix + 'Admin/Detail/:idx'
		,name: 'AdminDetail'
		,component: () => import('@/view/Admin/AdminDetail.vue')
	}
	,{
		path: prefix + 'Agency/List'
		,name: 'AgencyList'
		,component: () => import('@/view/Agency/AgencyList.vue')
	}
	,{
		path: prefix + 'Agency/Item'
		,name: 'AgencyItem'
		,component: () => import('@/view/Agency/AgencyItem.vue')
	}
	,{
		path: prefix + 'Agency/Detail/:idx'
		,name: 'AgencyDetail'
		,component: () => import('@/view/Agency/AgencyDetail.vue')
	}
	,{
		path: prefix + 'Manager/DistributorList'
		,name: 'ManagerDistributorList'
		,component: () => import('@/view/Manager/ManagerDistributorList.vue')
	}
	,{
		path: prefix + 'Manager/AgencyList'
		,name: 'ManagerAgencyList'
		,component: () => import('@/view/Manager/ManagerAgencyList.vue')
	}
	,{
		path: prefix + 'Supply/List'
		,name: 'SupplyList'
		,component: () => import('@/view/Supply/SupplyList.vue')
	}
	,{
		path: prefix + 'Supply/Item'
		,name: 'SupplyItem'
		,component: () => import('@/view/Supply/SupplyItem.vue')
	}
	,{
		path: prefix + 'Supply/Detail/:idx'
		,name: 'SupplyDetail'
		,component: () => import('@/view/Supply/SupplyDetail.vue')
	}
	,{
		path: prefix + 'Product/List/:pdt_div?'
		,name: 'ProductList'
		,component: () => import('@/view/Product/ProductList.vue')
	}
	,{
		path: prefix + 'Product/Sales/:page?'
		,name: 'ProductSales'
		,component: () => import('@/view/Product/ProductSales.vue')
	}
	,{
		path: prefix + 'Product/ListAgency/:page?'
		,name: 'ProductListAgency'
		,component: () => import('@/view/Product/ProductListAgency.vue')
	}
	,{
		path: prefix + 'Product/ListSupply'
		,name: 'ProductListSupply'
		,component: () => import('@/view/Product/ProductListSupply.vue')
	}
	,{
		path: prefix + 'Product/Item/:pdt_div?'
		,name: 'ProductItem'
		,component: () => import('@/view/Product/ProductItem.vue')
	}
	,{
		path: prefix + 'Product/ItemMobile/:pdt_div?'
		,name: 'ProductItemMobile'
		,component: () => import('@/view/Product/ProductItemMobile.vue')
	}
	,{
		path: prefix + 'Product/Detail/:uid?'
		,name: 'ProductDetail'
		,component: () => import('@/view/Product/ProductDetail.vue')
	}
	,{
		path: prefix + 'Product/DetailMobile/:uid?'
		,name: 'ProductDetailMobile'
		,component: () => import('@/view/Product/ProductDetailMobile.vue')
	}
	,{
		path: prefix + 'Product/DetailSupply/:uid?'
		,name: 'ProductDetailSupply'
		,component: () => import('@/view/Product/ProductDetailSupply.vue')
	}
	,{
		path: prefix + 'Product/DetailAgency/:uid'
		,name: 'ProductDetailAgency'
		,component: () => import('@/view/Product/ProductDetailAgency.vue')
	}
	,{
		path: prefix + 'Product/Category/:type?'
		,name: 'ProductCategory'
		,component: () => import('@/view/Product/ProductCategory.vue')
	}
	,{
		path: prefix + 'Order/Cart'
		,name: 'Cart'
		,component: () => import('@/view/Order/Cart.vue')
	}
	,{
		path: prefix + 'Order/Form'
		,name: 'OrderForm'
		,component: () => import('@/view/Order/OrderForm.vue')
	}
	,{
		path: prefix + 'Order/Result/:order_number'
		,name: 'OrderResult'
		,component: () => import('@/view/Order/OrderResult.vue')
	}
	,{
		path: prefix + 'Order/List/:pdt_div?'
		,name: 'OrderList'
		,component: () => import('@/view/Order/OrderList.vue')
	}
	,{
		path: prefix + 'Order/Mobile'
		,name: 'OrderMobile'
		,component: () => import('@/view/Order/OrderMobile.vue')
	}
	,{
		path: prefix + 'Order/RegularList'
		,name: 'OrderRegularList'
		,component: () => import('@/view/Order/OrderRegularList.vue')
	}
	,{
		path: prefix + 'Order/RegularDetail/:idx'
		,name: 'OrderRegularDetail'
		,component: () => import('@/view/Order/OrderRegularDetail.vue')
	}
	,{
		path: prefix + 'Order/ListSupply/:page?'
		,name: 'OrderListSupply'
		,component: () => import('@/view/Order/OrderListSupply.vue')
	}
	,{
		path: prefix + 'Order/ListAgency/:page?'
		,name: 'OrderListAgency'
		,component: () => import('@/view/Order/OrderListAgency.vue')
	}
	,{
		path: prefix + 'Banner/List/:page?'
		,name: 'BannerList'
		,component: () => import('@/view/Banner/BannerList.vue')
	}
	,{
		path: prefix + 'Banner/Item'
		,name: 'BannerItem'
		,component: () => import('@/view/Banner/BannerItem.vue')
	}
	,{
		path: prefix + 'Banner/Detail/:banner_uid'
		,name: 'BannerDetail'
		,component: () => import('@/view/Banner/BannerDetail.vue')
	}
	,{
		path: prefix + 'Popup/List/:page?'
		,name: 'PopupList'
		,component: () => import('@/view/Popup/PopupList.vue')
	}
	,{
		path: prefix + 'Popup/Item'
		,name: 'PopupItem'
		,component: () => import('@/view/Popup/PopupItem.vue')
	}
	,{
		path: prefix + 'Popup/Detail/:popup_uid'
		,name: 'PopupDetail'
		,component: () => import('@/view/Popup/PopupDetail.vue')
	}
	,{
		path: prefix + 'Setting/Code/List'
		,name: 'SettingCodeList'
		,component: () => import('@/view/Setting/CodeList.vue')
	}
	,{
		path: prefix + 'Etc/Excel/Download'
		,name: 'EtcExcelDownload'
		,component: () => import('@/components/Excel')
	}
	,{
		path: prefix + 'Settlement/Management'
		,name: 'SettlementManagement'
		,component: () => import('@/view/Settlement/SettlementManagement')
	}
	,{
		path: prefix + 'Settlement/List'
		,name: 'SettlementList'
		,component: () => import('@/view/Settlement/SettlementList')
	}
	,{
		path: prefix + 'Settlement/Agency'
		,name: 'SettlementAgency'
		,component: () => import('@/view/Settlement/SettlementAgency')
	}
	,{
		path: prefix + 'Settlement/Supply'
		,name: 'SettlementSupply'
		,component: () => import('@/view/Settlement/SettlementSupply')
	}
	,{
		path: prefix + 'Settlement/Delivery'
		,name: 'SettlementDeliveryList'
		,component: () => import('@/view/Settlement/SettlementDeliveryList')
	}
	,{
		path: prefix + 'Settlement/Minus'
		,name: 'SettlementMinus'
		,component: () => import('@/view/Settlement/SettlementMinus')
	}
	,{
		path: prefix + 'Board/Bbs/:b_code/Item'
		,name: 'BbsItem'
		,component: () => import('@/view/Board/BbsItem')
	}
	,{
		path: prefix + 'Board/Bbs/:b_code/Detail/:bbs_uid'
		,name: 'BbsDetail'
		,component: () => import('@/view/Board/BbsDetail')
	}
	,{
		path: prefix + 'Board/Bbs/:b_code/View/:bbs_uid'
		,name: 'BbsView'
		,component: () => import('@/view/Board/BbsView')
	}
	,{
		path: prefix + 'Board/Bbs/:b_code/Answer/:bbs_uid'
		,name: 'BbsAnswer'
		,component: () => import('@/view/Board/BbsAnswer')
	}
	,{
		path: prefix + 'CustomerCenter/Notice/List/:page?'
		,name: 'CustomerCenterNoticeList'
		,component: () => import('@/view/CustomerCenter/NoticeList')
	}
	,{
		path: prefix + 'CustomerCenter/Faq/List/:page?'
		,name: 'CustomerCenterFaqList'
		,component: () => import('@/view/CustomerCenter/FaqList')
	}
	,{
		path: prefix + 'CustomerCenter/QnA/List/:page?'
		,name: 'CustomerCenterQnAList'
		,component: () => import('@/view/CustomerCenter/QnAList')
	}
	,{
		path: prefix + 'CustomerCenter/Review/List/:page?'
		,name: 'CustomerCenterReviewList'
		,component: () => import('@/view/CustomerCenter/ReviewList')
	}
	,{
		path: prefix + 'Mypage/Supply'
		,name: 'MypageSupply'
		,component: () => import('@/view/Supply/SupplyView')
	}
	,{
		path: prefix + 'Mypage/Agency'
		,name: 'MypageAgency'
		,component: () => import('@/view/Agency/AgencyView')
	}
	,{
		path: prefix + 'Mypage/Schedule'
		,name: 'MypageSchedule'
		,component: () => import('@/view/Agency/AgencySchedule')
	}
	,{
		path: prefix + 'Point/Items',
		name:'PointItems',
		component: () => import('@/view/Point/PointItems')
	}
	,{
		path: prefix + 'Point/History',
		name:'PointHistory',
		component: () => import('@/view/Point/PointHistory')
	}
	,{
		path: prefix + 'Error/403',
		name:'403',
		component: () => import('@/view/Error/Forbidden.vue')
	}
	,{
		path: prefix + '*',
		name:'404', 
		component: () => import('@/view/Error/NotFound.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	// console.log('from: ' + from.path + ' to : ' + to.path)
	next();
});

export default router
